*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none !important;
    text-decoration: none !important;
}
body {
    overflow-x: hidden !important;
    background: #e8eef1;
  }

:root{
    --primary-color:#52BBAB;
    --black:#000;
    --light-black:#3d3636;
}

/* onbording1 */
.onbording-logo{
    height: 250px;
    width: 250px;
}
.onbording2-logo{
    height: 120px;
    width: 120px;
}
.onbording1-heading{
    font-size: 96px;
    letter-spacing: 15px;
}
.onbording1-subtitle{
    font-size: 20px;
    letter-spacing: 4px;
}

/* login1 */
.login-heading{
    font-size: 40px;
    color: var(--primary-color);
    font-weight: 600;
}
.login-subText{
    font-size: 16px;
    font-weight: 400;
    color: #9e9b9b;
}
.two-side-line{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #9e9b9b;
}
.two-side-line::before,.two-side-line::after{
    content: '';
    border-top: 1px solid #9e9b9b;
    margin: 0 20px 0 0;
    flex: 1 0 20px;
}
.two-side-line::after{
    margin: 0 0 0 20px;
}

/* forgot */
.forgot-heading{
    font-size: 40px;
    color: var(--light-black);
    font-weight: 600;
}
.fit-to-screen{
    min-height: calc(100vh - 123px);
  }
  .fulled-screen{
    min-height: calc(100vh - 48px);
  }